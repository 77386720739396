import { useState } from "react";

const useModalToggle = (
  initialState: boolean = false,
): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };

  return [isOpen, toggleModal];
};

export default useModalToggle;
