"use client";
import { Button } from "../../Basic/Button";
import GoogleIcon from "@/assets/icons/google.svg";
import { ThemedIcon } from "../../Basic/Icon";
import config from "@/shared/config/client";
import queryString from "query-string";

export interface Props {
  previousUrl: string;
  referralCode?: string;
}

const SsoLoginButtons = ({ previousUrl, referralCode }: Props) => {
  const qs = queryString.stringify({ redirectUrl: previousUrl, referralCode });
  return (
    <>
      <Button
        href={`${config.apiUrl}/auth/google?${qs}`}
        startIcon={<GoogleIcon />}
        className="w-full max-w-[250px]"
      >
        Sign in with Google
      </Button>
      <Button
        href={`${config.apiUrl}/auth/apple?${qs}`}
        startIcon={<ThemedIcon className="w-6 h-6" iconName="apple" />}
        className="w-full bg-invert text-invert endemic:bg-primary max-w-[250px] shadow-none"
      >
        Sign in with Apple
      </Button>
    </>
  );
};

export default SsoLoginButtons;
