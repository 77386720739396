"use client";
import { twMerge } from "tailwind-merge";
import { NavigationActiveItemIndicator } from "../../Layout/Navigation/NavigationActiveItemIndicator";

export interface Props {
  id: string;
  label: string;
  isActive: boolean;
  onClick: (id: string) => void;
}

function TabNavigationItem({ id, label, onClick, isActive }: Props) {
  return (
    <span
      onClick={() => onClick(id)}
      className={twMerge(
        "hover:cursor-pointer grow shrink basis-0 text-lg leading-6 text-center relative",
        isActive ? "text-red endemic:text-yellow font-bold" : "font-normal",
      )}
    >
      {label}

      {isActive && <NavigationActiveItemIndicator className="lg:-bottom-4" />}
    </span>
  );
}

export default TabNavigationItem;
