"use client";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import TabNavigationItem from "./TabNavigationItem";
import styles from "./Tabs.module.css";

export interface TabItem {
  id: string;
  label: string;
  content: React.ReactNode;
}
export interface Props {
  tabs: Array<TabItem>;
  separator?: ReactNode;
  initialTab?: string;
  navigationClassName?: string;
  contentClassName?: string;
  showNavigation?: boolean;
}

function Tabs({
  tabs,
  separator,
  initialTab = tabs[0].id,
  navigationClassName,
  contentClassName,
  showNavigation = true,
}: Props) {
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <>
      {showNavigation && (
        <div
          className={twMerge(
            "flex justify-evenly items-center text-center font-center gap-6 overflow-y-hidden pb-14",
            styles.scrollbarHide,
            !!separator && "lg:hidden lg:gap-x-8 xl:gap-x-12",
            navigationClassName,
          )}
        >
          {tabs.map((tab) => (
            <TabNavigationItem
              key={tab.id}
              id={tab.id}
              label={tab.label}
              onClick={setActiveTab}
              isActive={activeTab === tab.id}
            />
          ))}
        </div>
      )}
      <div
        className={twMerge(
          "pt-20 mb-36 flex",
          !!separator && "lg:pt-0",
          contentClassName,
        )}
      >
        {tabs.map((t, index) => (
          <Fragment key={index}>
            <div
              key={t.id}
              className={twMerge(
                "w-full",
                t.id !== activeTab &&
                  (separator ? "hidden lg:block" : "hidden"),
                !!separator && "lg:flex-1",
              )}
            >
              <div className="max-w-md m-auto h-full">{t.content}</div>
            </div>
            <div className={twMerge("hidden", !!separator && "lg:block")}>
              {index !== tabs.length - 1 ? separator : null}
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}

export default Tabs;
