"use client";
// import Link from "next/link";
import { Button } from "../../Basic/Button";
import { ThemedIcon } from "../../Basic/Icon";
import { FormInput } from "../../Basic/FormInput";
import { Form, Formik, FormikProps } from "formik";
import { credentialsSignIn } from "@/api/auth";
import { Type } from "../../Basic/Button/constants";
import { MouseEvent, useEffect, useState } from "react";
import useModalToggle from "@/shared/hooks/useToggleModal";
import { RequestPasswordResetModal } from "./RequestPasswordResetModal";

type FormData = {
  email: string;
  password: string;
};

const initialValues = {
  email: "",
  password: "",
};

const CredentialsForm = ({ previousUrl }: { previousUrl: string }) => {
  const [loginError, setLoginError] = useState("");
  const [isRequestPasswordResetModalOpen, toggleRequestPasswordResetModal] =
    useModalToggle();

  const handlePasswordReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleRequestPasswordResetModal();
  };

  //workaround to fill formik values on chrome and mozilla browser fill(problem with controlled components)
  const AutofillSync = ({
    props: {
      values: { email, password },
      setValues,
    },
  }: {
    props: FormikProps<FormData>;
  }) => {
    useEffect(() => {
      if (
        (document.querySelector('input[name="email"]') as HTMLInputElement)
          ?.value ||
        (document.querySelector('input[name="password"]') as HTMLInputElement)
          ?.value
      ) {
        if (!email || !password) {
          setValues({
            email:
              (
                document.querySelector(
                  'input[name="email"]',
                ) as HTMLInputElement
              )?.value || "",
            password:
              (
                document.querySelector(
                  'input[name="password"]',
                ) as HTMLInputElement
              )?.value || "",
          });
        }
      }
    }, [email, password, setValues]);

    return null;
  };

  return (
    <>
      <RequestPasswordResetModal
        isOpen={isRequestPasswordResetModalOpen}
        onClose={() => toggleRequestPasswordResetModal()}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, helpers) => {
          if (!values.email || !values.password) {
            setLoginError("Missing email or password");
            return;
          }

          try {
            await credentialsSignIn(values.email, values.password);
            window.location.href = previousUrl;
          } catch (error: any) {
            setLoginError(error?.message);
            helpers.resetForm();
          }
        }}
      >
        {(props) => (
          <>
            <AutofillSync props={props} />
            <Form
              method="post"
              className="flex flex-col items-center"
              onChange={() => setLoginError("")}
            >
              <div className="mb-2 w-full max-w-[250px]">
                <FormInput
                  type="text"
                  name="email"
                  label="E-mail address"
                  placeholder="Enter email address"
                />
                <FormInput
                  name="password"
                  type="password"
                  label="Your password"
                  placeholder="Enter password"
                />
                <span className="text-red font-semibold text-xs px-4 block leading-[0px] h-0 mb-4">
                  {loginError}
                </span>
              </div>
              <span className="mb-8">
                Forgot your password?{" "}
                <button
                  type="button"
                  onClick={(e) => handlePasswordReset(e)}
                  className="underline text-red endemic:text-white"
                >
                  Reset here
                </button>
              </span>
              <Button
                element={Type.Button}
                type="submit"
                startIcon={<ThemedIcon className="w-6 h-6" iconName="endy" />}
                className="w-full max-w-[135px] lg:max-w-[250px] mb-8 justify-center"
              >
                Log in
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CredentialsForm;
