import { withClientRequest } from "../requestBuilder/withClientRequest";
import config from "@shared/config/client";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import queryString from "query-string";

const requestPasswordReset =
  (request: ICallableRequestBuilder<void>) => (email: string) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/resetPassword/requestPasswordReset/${email}`,
    });
    return request.call(url, (init) => ({
      ...init,
      method: "POST",
    }));
  };

const resetPassword =
  (request: ICallableRequestBuilder<void>) =>
  (token: string, newPassword: string, confirmedPassword: string) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/resetPassword/${token}`,
    });
    return request.call(url, (init) => ({
      ...init,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newPassword,
        confirmedPassword,
      }),
    }));
  };

const clientRequests = {
  requestPasswordReset: withClientRequest(requestPasswordReset),
  resetPassword: withClientRequest(resetPassword),
};

export default clientRequests;
