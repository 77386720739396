"use client";
import React, { useState } from "react";
import { Modal } from "@/app/(components)/Basic/Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@/app/(components)/Basic/Button";
import { Type } from "@/app/(components)/Basic/Button/constants";
import { useMutation } from "@tanstack/react-query";
import { FormInput } from "@/app/(components)/Basic/FormInput";
import resetPasswordApi from "@/api/resetPassword/client";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .max(30, "Must be 30 characters or less")
    .required("Required"),
});

function RequestPasswordResetModal({ isOpen, onClose }: Props) {
  const [error, setError] = useState("");

  const requestPasswordResetMutation = useMutation({
    mutationFn: (email: string) => resetPasswordApi.requestPasswordReset(email),
    onSuccess: () => {
      onClose();
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  const handleSubmit = async (values: { email: string }) => {
    await requestPasswordResetMutation.mutateAsync(values.email);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton
      className="max-w-[500px]"
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="flex flex-col items-center gap-y-6">
            <h3 className="font-semibold">Reset password</h3>
            <span className="font-light">
              Please enter the e-mail address you used to register on this
              website. We will send you an email with instructions to reset your
              password.
            </span>
            <div className="w-60 mt-4">
              <FormInput
                name="email"
                label="Your e-mail address:"
                labelClassName="endemic:text-invert"
                type="email"
                className="endemic:focus:border-green endemic:text-invert"
              />
              <Button
                element={Type.Button}
                type="submit"
                fullWidth
                className="justify-center"
              >
                Submit
              </Button>
              <span className="block text-red text-center mt-4 ">{error}</span>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}

export default RequestPasswordResetModal;
