"use client";
import { Button } from "../Button";

import ArrowIcon from "@/assets/icons/arrow-right.svg";
import { useWeb3Actions } from "@/web3/context";
import { useWalletLogin } from "./hooks";
import {
  ConnectorType,
  CONNECTORS,
  CONNECTOR_ICONS,
  CONNECTOR_LABELS,
} from "@/shared/constants";
import { useSearchParams } from "next/navigation";

interface Props {
  className?: string;
  previousUrl: string;
  referralCode?: string;
  connector: ConnectorType;
}

const WalletLoginButton = ({ connector, className, previousUrl }: Props) => {
  const { connectToEthereum } = useWeb3Actions();
  const searchParams = useSearchParams();
  const referralCode = searchParams.get("referralCode");

  const { login } = useWalletLogin(previousUrl);
  const handleClick = async () => {
    const connection = await connectToEthereum(CONNECTORS[connector]);
    if (connection?.currentAddress && connection?.signer) {
      await login(connection.currentAddress, connection.signer, referralCode);
    }
  };

  return (
    <Button
      className={className}
      startIcon={CONNECTOR_ICONS[connector]}
      endIcon={<ArrowIcon className="fill-current w-4" />}
      onClick={handleClick}
    >
      {CONNECTOR_LABELS[connector]}
    </Button>
  );
};

export default WalletLoginButton;
