import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Login/CredentialsForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Login/SsoLoginButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/Tabs/Tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/WalletLoginButton/WalletLoginButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/graphics/backgrounds/register-background-1.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/graphics/backgrounds/register-background-2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/arbitrum.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/coinbase.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/ethereum.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/grey-endy.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/metamask.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/polygon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/turbo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/walletConnect.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/coinbase.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/metaMask.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/walletConnect.ts");
