"use client";
import { getNonce, signIn } from "@/api/auth/wallet";
import { useCallback } from "react";
import { getWalletLoginMessage } from "@/api/auth/helpers";
import { JsonRpcSigner } from "ethers";
import { useWeb3Actions } from "@/web3/context";

export function useWalletLogin(previousUrl: string) {
  const { disconnect } = useWeb3Actions();

  const login = useCallback(
    async (
      address: string,
      signer: JsonRpcSigner,
      referralCode?: string | null,
    ) => {
      try {
        if (!signer || !address) return;

        const { nonce } = await getNonce(address, undefined, referralCode);
        const message = getWalletLoginMessage(address, nonce);
        const signature = await signer.signMessage(message);

        const user = await signIn(address, signature);
        if (user) {
          window.location.href = previousUrl;
        }
      } catch (err: any) {
        await disconnect();
      }
    },
    [previousUrl, disconnect],
  );

  return { login };
}
